// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_rl d_gv d_cs";
export var heroHeaderCenter = "q_gw d_gw d_cs d_dw";
export var heroHeaderRight = "q_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "q_rm d_gr d_cw";
export var heroParagraphCenter = "q_gs d_gs d_cw d_dw";
export var heroParagraphRight = "q_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "q_rn d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "q_rp d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "q_rq d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "q_rr d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "q_rs d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "q_rt t_rt";
export var heroExceptionNormal = "q_rv t_rv";
export var heroExceptionLarge = "q_rw t_rw";
export var Title1Small = "q_rx t_rx t_rP t_rQ";
export var Title1Normal = "q_ry t_ry t_rP t_rR";
export var Title1Large = "q_rz t_rz t_rP t_rS";
export var BodySmall = "q_rB t_rB t_rP t_r6";
export var BodyNormal = "q_rC t_rC t_rP t_r7";
export var BodyLarge = "q_rD t_rD t_rP t_r8";