// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "r_rF d_bD";
export var storyRowWrapper = "r_hx d_hx d_bK";
export var storyLeftWrapper = "r_rG d_bz d_bP";
export var storyWrapperFull = "r_rH d_cD";
export var storyWrapperFullLeft = "r_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "r_mv d_hy";
export var storyLeftWrapperCenter = "r_rJ d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "r_rK d_hF";
export var storyHeader = "r_rL d_hD d_w d_cs";
export var storyHeaderCenter = "r_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "r_hB d_hB d_by d_dw";
export var storyBtnWrapper = "r_rM d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "r_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "r_qy d_fg d_Z";
export var imageWrapperFull = "r_q2 d_w d_H d_bf d_Z";
export var SubtitleSmall = "r_qd t_qd t_rP t_r0";
export var SubtitleNormal = "r_qf t_qf t_rP t_r1";
export var SubtitleLarge = "r_qg t_qg t_rP t_r2";
export var textLeft = "r_dv";
export var textCenter = "r_dw";
export var textRight = "r_dx";